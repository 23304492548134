import {
  mdiAccountCheckOutline,
  mdiAccountOutline,
  mdiAccountPlusOutline,
  mdiAccountRemoveOutline,
  mdiCogOutline,
  mdiDatabaseOutline,
  mdiDnsOutline,
  mdiPencilOutline,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import { i18n } from '@/plugins/i18n'

export default function useUsersList() {
  const blankItem = {
    id: null,
    first_name: null,
    last_name: null,
    email: null,
    phone: null,
    status: null,
    post: null,
    avatar: null,
    user_id: null,
    role_id: null,
    password: null,
    changePassword: true,
    quota: 100,
  }
  const form = ref(null)
  const items = ref([])
  const filters = ref([
    {
      text: 'Id', value: 'id', filter: { place_holder: '', type: 'number' }, align: 'center', width: '100px',
    },
    { text: 'User', value: 'name', filter: { place_holder: '', type: 'text' } },
    {
      text: 'Image',
      value: 'avatar',
      align: 'left',
      cellClass: 'text-left',
      editable: true,
      type: 'image',
      sortable: false,
      width: '100px',
      height: '100px',
      ratio: 16 / 16,
      cropHeight: 700,
      crowWidth: 700,
      info: 'Confirm the update of "Image" ?',
      fields: [
        {
          type: 'image',
          label: 'Image',
          value: 'avatar',
          required: false,
          width: '100px',
          height: '100px',
          ratio: 16 / 16,
          cropHeight: 700,
          crowWidth: 700,
        },
      ],
    },
    { text: 'First Name', value: 'firstname', filter: { place_holder: '', type: 'text' } },
    { text: 'Last Name', value: 'lastname', filter: { place_holder: '', type: 'text' } },
    { text: 'Phone', value: 'tel', filter: { place_holder: '', type: 'text' } },
    { text: 'E-mail', value: 'email', filter: { place_holder: '', type: 'text' } },
    { text: 'Whatsapp', value: 'whatsapp', filter: { place_holder: '', type: 'text' } },
    { text: 'Professional phone', value: 'professional_phone', filter: { place_holder: '', type: 'text' } },
    { text: 'Location', value: 'location.adr_address', filter: { place_holder: '', type: 'text' } },
    { text: 'Address', value: 'address', filter: { place_holder: '', type: 'text' } },
    { text: 'About', value: 'about', filter: { place_holder: '', type: 'text' } },
    {
      text: 'Roles',
      value: 'long_roles_names',
      sortable: false,
      filter: {
        place_holder: '',
        type: 'select',
        values: store.state['app-user'].roles,
        label: 'display_name',
        id: 'name',
        multiple: true,
        column: 'users.role_id',
        join_table: 'roles',
        join_column: 'roles.id',
        local_column: 'users.role_id',
      },
    },
    {
      text: 'Credit',
      value: 'credit',
    },
    {
      text: 'Status',
      value: 'status',
      filter: {
        place_holder: '', type: 'select', column: 'status', values: [{ id: 1, label: 'Active' }, { id: 0, label: 'Inactive' }], label: 'label', id: 'id', multiple: false,
      },
    },
    {
      text: 'Doctor',
      value: 'doctor',
      filter: {
        place_holder: '', type: 'select', column: 'status', values: [{ id: 1, label: 'Doctor' }, { id: 0, label: 'User' }], label: 'label', id: 'id', multiple: false,
      },
    },

    {
      text: 'Specialties',
      value: 'specialties',
      object: 'specialty',
      with: 'specialties',
      type: 'select',
      sortable: false,
      translatable: true,
      info: 'Confirm the update of "Type" ?',
      requiredMessage: 'Type is required',
      width: '150px',
      multiple: true,
      selected_value: 'id',
      selected_label: 'name',
      itemLabel: 'name',
      itemValue: 'id',
      items: [],
      source: '/admin/specialties',
      editable: false,
      column_name: 'specialties_ids',
      sm: 12,
      fields: [
        {
          type: 'select',
          label: 'Specialty',
          value: 'specialties_ids',
          items: [],
          source: '/admin/specialties',
          object: 'specialties',
          itemLabel: `name.${i18n.locale}`,
          itemValue: 'id',
          multiple: true,
          associated: 'specialties',
        },
      ],
      filter: {
        place_holder: '',
        type: 'select',
        source: '/admin/specialties',
        label: `name.${i18n.locale}`,
        id: 'id',
        multiple: true,
        items: [],
        key: 'user_specialty.specialty_id',
        column: 'user_specialty.specialty_id',
        join_table: 'user_specialty',
        join_column: 'user_specialty.user_id',
        local_column: 'specialties.id',
      },
    },
    {
      text: 'Price', value: 'price', editable: true,
      fields: [
        { type: 'number', label: 'Price', value: 'price' },
      ],
      filter: { place_holder: '', type: 'number' },
    },
    {
      text: 'Year of birth',
      value: 'year_of_birth',
      filters: [
        {
          key: 'year_of_birth',
          place_holder: '',
          type: 'range',
          column: 'year_of_birth',
          min: 1950,
          max: new Date().getFullYear() - 12,
          step: 1,
        },
      ],
    },
    {
      text: 'Created At',
      value: 'created_at',
      align: 'center',
      cellAlign: 'center',
      menu: false,
      type: 'date',
      width: '150px',
      editable: true,
      date_format: {
        month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric',
      },
      fields: [
        { type: 'date', label: 'Created At', value: 'created_at' },
      ],
      filter: {
        place_holder: 'Date1', type: 'date', range: true,
      },
      filters: [
        {
          key: 'created_at',
          text: 'Created At',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Is empty' },
            { id: 1, label: 'Not empty' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'ride_created_at',
          column: 'created_at',
        },
      ],
    },
    {
      text: 'Gender',
      value: 'gender',
      align: 'center',
      filter: {
        place_holder: '', type: 'select', column: 'gender', values: [{ id: 'F', label: 'Female' }, { id: 'M', label: 'Male' }, { id: 'X', label: 'Male' }], label: 'label', id: 'id', multiple: false,
      },
    },
    {
      text: 'Promo Code', value: 'promo_code', editable: true,
      fields: [
        { type: 'text', label: 'Promo Code', value: 'promo_code' },
      ],
      filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ])
  const item = ref({})
  const tableColumns = [
    { text: 'UTILISATEUR', value: 'name' },
    { text: 'Code', value: 'code' },
    { text: 'E-MAIL', value: 'email' },
    { text: 'ROLE', value: 'role', sortable: false },
    { text: 'POST', value: 'post' },
    { text: 'STATUS', value: 'status' },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]
  const isSnackbarBottomVisible = ref(false)
  const snackBarMessage = ref('')
  const isFormActive = ref(false)
  const deleteDialog = ref(false)
  const searchQuery = ref('')
  const roleFilter = ref(null)
  const supervisorFilter = ref(null)
  const statusFilter = ref(null)
  const totalItems = ref(0)
  const loading = ref(false)
  const options = ref({
    rowsPerPage: 50,
    itemsPerPage: 50,
    page: 1,
    sortDesc: [true],
  })
  const page = ref(1)
  const pageCount = ref(0)
  const userTotalLocal = ref([])
  const selectedRows = ref([])
  const resetForm = () => {
    if (form.value) form.value.reset()
  }

  // new item
  const openDialog = val => {
    if (val) {
      item.value = val
      item.value.changePassword = false
    } else {
      item.value = JSON.parse(JSON.stringify(blankItem))
      resetForm()
    }
    isFormActive.value = !isFormActive.value
  }

  /*  store
    .dispatch('app-user/fetchRoles', {}) */

  const select = data => {
    if (data.state) {
      selectedRows.value.push(data.item)
    } else {
      selectedRows.value.splice(selectedRows.value.findIndex(ele => ele.id === data.item.id), 1)
    }
  }

  // fetch data
  const fetchUsers = () => {
    isFormActive.value = false
    const singleFilters = filters.value.filter(ele => ele.filter != null && ele.filter.value !== '' && ele.filter.value != null && (ele.filter.value.constructor !== Array || ele.filter.value.length > 0)).map(ele => ({
      ...ele.filter,
      key: ele.value,
      values: null,
    }))

    // const multipleFilters = filters.value.filter(ele => ele.filters != null && ele.filters.length > 0).map(th => th.filters.map(ele => ({ ...ele.filter, key: ele.value, values: null })))
    const multipleFilters = filters.value.filter(ele => ele.filters != null && ele.filters.length > 0)
    const selectedMultipleFilters = []
    for (let i = 0; i < multipleFilters.length; i++) {
      selectedMultipleFilters.push(...multipleFilters[i].filters
        .filter(ele => ele.value !== '' && ele.value != null && (ele.value.constructor !== Array || ele.value.length > 0))
        .map(ele => ({ ...ele, values: null/* , type: null */ })))
    }

    store
      .dispatch('app-user/fetchUsers', {
        /* filters: filters.value.filter(ele => ele.filter != null && ele.filter.value !== '' && ele.filter.value != null && (ele.filter.value.constructor !== Array || ele.filter.value.length > 0)).map(ele => ({ ...ele.filter, key: ele.value, values: null })),
*/
        filters: [...singleFilters, ...selectedMultipleFilters],
        term: searchQuery.value,
        status: statusFilter.value,
        role: roleFilter.value,
        supervisor: supervisorFilter.value,
        orderBy: options.value.sortBy ? options.value.sortBy[0] : 'id',
        // eslint-disable-next-line no-nested-ternary
        direction: options.value.sortDesc ? (options.value.sortDesc[0] ? 'DESC' : 'ASC') : 'DESC',
        page: options.value.page,
        per_page: options.value.itemsPerPage,
      })
      .then(response => {
        const { data, total, userTotal } = response.data

        items.value = data.map(object => {
          object.changePassword = false

          // object.role = object.roles && object.roles.length > 0 ? object.roles[0] : null
          /* if(object.long_roles_names) {
            object.long_roles_names = object.long_roles_names
          } */
          object.loading = false

          object.specialties = object.specialties || []
          object.specialties_ids = object.specialties.map(el => el.id)

          return object
        })

        store.commit('app-user/USERS', items.value)
        totalItems.value = total
        userTotalLocal.value = userTotal

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }
  const confirmDelete = val => {
    item.value = val
    deleteDialog.value = true
  }
  const deleteItem = () => {
    if (item.value) {
      loading.value = true
      store.dispatch('app-user/deleteUser', item.value)
        .then(response => {
          loading.value = false
          fetchUsers()
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {

        })
    }
  }

  // eslint-disable-next-line no-unused-vars
  let timeout = null
  watch([filters], () => {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      loading.value = true
      selectedRows.value = []
      fetchUsers()
    }, 500)
  }, { deep: true })

  watch([searchQuery, roleFilter, supervisorFilter, statusFilter, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchUsers()
  }, { deep: true })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'administrator') return 'error'

    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return mdiAccountOutline
    if (role === 'author') return mdiCogOutline
    if (role === 'maintainer') return mdiDatabaseOutline
    if (role === 'editor') return mdiPencilOutline
    if (role === 'administrator') return mdiDnsOutline

    return mdiAccountOutline
  }

  const resolveUserStatusName = status => {
    if (status === 0) return 'Inactive'
    if (status === 1) return 'Active'

    return 'Active'
  }

  const resolveUserStatusVariant = status => {
    if (status === 0) return 'warning'
    if (status === 1) return 'success'

    return 'primary'
  }

  const resolveUserTotalIcon = total => {
    if (total === 'Total Users') return { icon: mdiAccountOutline, color: 'primary' }
    if (total === 'Paid Users') return { icon: mdiAccountPlusOutline, color: 'error' }
    if (total === 'Active Users') return { icon: mdiAccountCheckOutline, color: 'success' }
    if (total === 'Pending Users') return { icon: mdiAccountRemoveOutline, color: 'warning' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  const saved = data => {
    items.value[data.index] = data.item
  }

  return {
    saved,
    item,
    items,
    tableColumns,
    searchQuery,
    roleFilter,
    supervisorFilter,
    statusFilter,
    filters,
    totalItems,
    loading,
    options,
    userTotalLocal,
    selectedRows,
    select,
    isFormActive,
    blankItem,
    form,
    page,
    pageCount,
    snackBarMessage,
    isSnackbarBottomVisible,
    resetForm,
    openDialog,
    deleteDialog,
    confirmDelete,
    deleteItem,
    fetchUsers,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveUserStatusName,
    resolveUserTotalIcon,
  }
}
